import App from './App.vue'
import router from './router'
import store from './store'
import Vue from 'vue'
import VueGtag from 'vue-gtag'
import VueSweetalert2 from 'vue-sweetalert2'

import '@/assets/css/ui-kit.css'
import 'sweetalert2/dist/sweetalert2.min.css'

const swalOptions = {
  confirmButtonColor: '#718D57',
  allowOutsideClick: false,
  allowEscapeKey: false,
  confirmButtonText: '確定',
}
Vue.use(VueSweetalert2, swalOptions)

Vue.use(
  VueGtag,
  {
    config: { id: process.env.NODE_ENV === 'production' ? 'G-S1Y6FE98JD' : 'G-6XFPZVY0C5' },
  },
  router
)

Vue.config.productionTip = false

window.vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
