import _ from 'lodash'
import axios from 'axios'
import packageJson from '../../package.json'
import Papa from 'papaparse'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const getCsv = async (url, cachetime = 3e4) => {
  const csv = _.trim(_.get(await axios.get(url, {
    params: { cachebust: _.floor(Date.now() / cachetime) },
  }), 'data'))
  return _.get(Papa.parse(csv, {
    encoding: 'utf8',
    header: true,
  }), 'data', [])
}

export default new Vuex.Store({
  state: {
    config: {
      version: packageJson.version + (process.env.NODE_ENV === 'production' ? '' : `.${packageJson.buildVersion}`),
    },
    herbalDatas: [],
  },
  getters: {},
  mutations: {
    setState (state, { key, value }) {
      state[key] = value
    },
  },
  actions: {
    async getHerbals ({ commit }) {
      try {
        let [datas, images] = await Promise.all([
          getCsv('https://docs.google.com/spreadsheets/d/e/2PACX-1vQA1PvZ9GIJU4YJScn-AQu_8C-we5zNuKQE7Yv720fj4pfT7C7YFG1SOkth4aaT8xel6x74PiujBdva/pub?gid=423701819&single=true&output=csv'),
          getCsv('https://docs.google.com/spreadsheets/d/e/2PACX-1vQA1PvZ9GIJU4YJScn-AQu_8C-we5zNuKQE7Yv720fj4pfT7C7YFG1SOkth4aaT8xel6x74PiujBdva/pub?gid=400148214&single=true&output=csv'),
        ])
        if (!datas.length) throw new Error(404, '資料來源為空')
        datas = _.map(datas, data => ({
          ...data,
          usage: data.usage.replace(/\r\n|\r|\n/g, '<br>'),
          image: _.get(_.find(images, { id: data.id }), 'image', 'https://i.imgur.com/4OlugFq.png'),
        }))
        commit('setState', { key: 'herbalDatas', value: datas })
      } catch (err) {
        console.error(err)
      }
    },
  },
  modules: {},
})
