<template>
  <div :class="rukaiHomeClass">
    <router-view/>
    <footer>
      v{{ $store.state.config.version }}
    </footer>
  </div>
</template>

<script>
window.showLoading = (title, text) => {
  window.vm.$swal.fire({
    title,
    text,
    allowOutsideClick: false,
    showConfirmButton: false,
    willOpen: () => { window.vm.$swal.showLoading() },
  })
}

export default {
  computed: {
    rukaiHomeClass () {
      return this.$route.path.toLowerCase() === '/' ? 'bg-round' : ''
    },
  },
}
</script>

<style scoped>
footer {
  background: #868E9530;
  border-radius: 3px;
  bottom: 3px;
  padding: 1px 5px;
  position: fixed;
  right: 3px;
  z-index: 999;
}
</style>
